<template v-if="schema">
  <div v-if="schema" class="dealer-rating">
    <div
      class="sr-only"
      itemscope="itemscope"
      itemprop="aggregateRating"
      itemtype="https://schema.org/AggregateRating"
    >
      <meta itemprop="name" :content="dealerName" />
      <meta itemprop="itemReviewed" :content="dealerName" />
      <meta itemprop="worstRating" content="1" />
      <meta itemprop="ratingValue" :content="dealer.avg_rating" />
      <meta itemprop="bestRating" content="5" />
      <meta itemprop="reviewCount" :content="dealer.total_ratings" />
    </div>

    <Number :number="dealer.avg_rating" />

    <Stars id="dealer" :rating="dealer.avg_ratings" />
    <Total :rating="dealer.total_ratings" />
  </div>
  <div v-else class="dealer-rating">
    <Number :number="dealer.avg_rating" />
    <Stars id="dealer" :rating="dealer.avg_ratings" />
    <Total :rating="dealer.total_ratings" />
  </div>
</template>

<script>
import Number from "./Number.vue";
import Stars from "./Stars.vue";
import Total from "./Total.vue";

export default {
  name: "Dealer",
  components: {
    Number,
    Stars,
    Total
  },
  props: {
    schema: Boolean
  },
  computed: {
    dealer() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].dealer
        : null;
    },
    dealerName() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].dealerName
        : null;
    }
  }
};
</script>
