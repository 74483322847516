<template>
  <div class="total">
    <h3>{{ translation.overall_rating }}</h3>
    <p>({{ rating }} {{ translation.reviews }})</p>
  </div>
</template>

<script>
export default {
  name: "Total",
  props: {
    rating: Number
  },
  computed: {
    translation() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].translation
        : null;
    }
  }
};
</script>
