<template>
  <article class="detail">
    <header class="detail__header">
      <h1 v-if="dealerName">{{ dealerName }}</h1>
    </header>
    <div class="detail__content">
      <div class="detail__information">
        <h2 class="text--left">
          {{ translation.dealer_reviews }}
        </h2>
      </div>
      <div class="detail__dealer-rating">
        <Dealer />
      </div>
      <div class="detail__dealer-category">
        <Category
          v-for="(category, index) in dealer.rating_categories"
          :category="category"
          :key="index"
        />
      </div>

      <div
        v-if="!hideComments && comments && comments.length"
        class="detail__dealer-slider"
        @click="pause"
      >
        <h2 class="divider text--left">
          {{ translation.all_reviews }}
        </h2>
        <VueSlickCarousel v-if="status" ref="slick" v-bind="slickSettings">
          <div v-for="(comment, index) in comments" :key="index">
            <Comment v-on:resize="resizeSlick" :comment="comment" />
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </article>
</template>

<script>
import Dealer from './rating/Dealer.vue'
import Comment from './review/Comment.vue'
import Category from './Category.vue'
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'Detail',
  components: {
    Dealer,
    Category,
    Comment,
    VueSlickCarousel
  },
  data() {
    return {
      slickSettings: {
        accessibility: true,
        arrows: true,
        dots: true,
        fade: true,
        edgeFriction: 0.35,
        adaptiveHeight: true,
        pauseOnDotsHover: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
      },
      status: false
    }
  },
  props: {
    hideComments: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dealer() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].dealer
        : null
    },
    dealerName() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].dealerName
        : null
    },
    translation() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].translation
        : null
    },
    comments() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].comments
        : null
    }
  },
  methods: {
    pause: function() {
      this.$refs.slick.pause()
    },
    setStatus: function() {
      this.status = this.$parent.open ? true : false
    },
    resizeSlick: function() {
      this.$refs.slick.$forceUpdate()
    }
  },
  created() {
    this.$parent.$on('update', this.setStatus)
  }
}
</script>
