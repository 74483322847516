<template>
  <div class="category">
    <div class="width--100 text--left">
      <h2 class="divider p-relative">
        {{ translation[category.category_key.toLowerCase()] }}
      </h2>
    </div>

    <div class="width--20 text--left">
      &nbsp;
    </div>

    <div class="width--60">
      <Stars
        class="tiny"
        :id="category.category_key"
        :rating="category.category_avg_ratings"
      />
    </div>

    <div class="width--20 text--right">
      <p class="p--small text--bold">
        {{ category.category_avg_rating }}
      </p>
    </div>

    <div class="width--100">
      <Bars :rating="category.category_ratings" />
    </div>
  </div>
</template>

<script>
import Stars from './rating/Stars.vue'
import Bars from './rating/Bars.vue'

export default {
  name: 'Category',
  components: {
    Stars,
    Bars
  },
  props: {
    category: Object
  },
  computed: {
    translation() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].translation
        : null
    }
  }
}
</script>
