<template>
  <div class="bar">
    <div class="width--20 text--left">
      <div class="p--small text--bold">
        <div class="bar--id">
          {{ id }}
        </div>
        <div class="bar--star">
          <Star :id="id" percentage="100%" />
        </div>
      </div>
    </div>
    <div class="width--60">
      <div class="bar__progress">
        <div class="bar__progress__bar" :style="{ width: percent }"></div>
      </div>
    </div>
    <div class="width--20 text--right">
      <p class="p--small text--bold">{{ percent }}</p>
    </div>
  </div>
</template>

<script>
import Star from './Star.vue'

export default {
  name: 'Bar',
  components: {
    Star
  },
  props: {
    id: String,
    percentage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      percent: this.percentage + '%'
    }
  },
  computed: {
    translation() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].translation
        : null
    }
  }
}
</script>
