var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"review-comment"},[_c('div',{staticClass:"review-comment__inner"},[_c('div',{staticClass:"sr-only"},[_c('div',{attrs:{"itemscope":"itemscope","itemprop":"review","itemtype":"https://schema.org/Review"}},[_c('meta',{attrs:{"itemprop":"itemreviewed","content":_vm.dealerName}}),_c('meta',{attrs:{"itemprop":"datePublished","content":_vm.comment.comment_date_iso}}),_c('meta',{attrs:{"itemprop":"author","content":_vm.translation.interviewee}}),_c('div',{attrs:{"itemscope":"itemscope","itemprop":"reviewRating","itemtype":"https://schema.org/Rating"}},[_c('meta',{attrs:{"itemprop":"worstRating","content":"1"}}),_c('meta',{attrs:{"itemprop":"ratingValue","content":_vm.comment.comment_rating}}),_c('meta',{attrs:{"itemprop":"bestRating","content":"5"}}),_c('meta',{attrs:{"itemprop":"description","content":""}}),_c('meta',{attrs:{"itemprop":"reviewBody","content":_vm.comment.comment_text}})])])]),_c('div',{staticClass:"review-comment__content width--50 text--left"},[_c('Stars',{staticClass:"tiny",attrs:{"id":'comment' + _vm.comment.comment_id,"rating":_vm.comment.comment_ratings}})],1),_c('div',{staticClass:"review-comment__content width--50 text--right"},[_c('p',{staticClass:"color--comment date"},[_vm._v(_vm._s(_vm.comment.comment_date))])]),_c('div',{staticClass:"review-comment__content width--100"},[_c('p',{staticClass:"p--small text--extra-bold"},[_vm._v(" "+_vm._s(_vm.translation[_vm.comment.category_key.toLowerCase()])+" ")]),(
          _vm.comment.comment_text && _vm.comment.comment_text.length >= _vm.maxLength
        )?_c('p',[(!_vm.showTotalCustomerComment)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.truncateText(_vm.comment.comment_text))}}),_c('div',{staticClass:"read-more",on:{"click":function($event){return _vm.toggleTotalCustomerComment()}}},[_vm._v(" "+_vm._s(_vm.translation.read_more)+" ")])]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.comment.comment_text)}})]],2):_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.comment.comment_text)}})])]),(
        _vm.comment.dealer_response &&
          _vm.comment.dealer_response.dealer_response_text
      )?_c('div',{staticClass:"width-100 background--comment review-comment__dealer"},[_c('span',{staticClass:"date-dealer"},[_vm._v(_vm._s(_vm.comment.dealer_response.dealer_response_date))]),_c('p',{staticClass:"p--small text--extra-bold"},[_vm._v(" "+_vm._s(_vm.translation.dealers_answer)+" ")]),(
          _vm.comment.dealer_response.dealer_response_text &&
            _vm.comment.dealer_response.dealer_response_text.length >= _vm.maxLength
        )?_c('p',[(!_vm.showTotalDealerResponse)?[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.truncateText(_vm.comment.dealer_response.dealer_response_text)
            )}}),_c('div',{staticClass:"read-more",on:{"click":function($event){return _vm.toggleTotalDealerResponse()}}},[_vm._v(" "+_vm._s(_vm.translation.read_more)+" ")])]:[_c('span',{domProps:{"innerHTML":_vm._s(_vm.comment.dealer_response.dealer_response_text)}})]],2):_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.comment.dealer_response.dealer_response_text)}})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }