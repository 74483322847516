import Vue from 'vue'
import qs from 'qs'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { demoDealerData } from './helper/dealer'
import FontFace from './../plugin/FontFace'

Vue.use(FontFace)
Vue.use(Vuex)
Vue.use(VueAxios, axios)

const parseQuerys = querysToUse => {
  var url = '?'
  Object.keys(querysToUse).forEach((key, id) => {
    url += `${id > 0 ? '&' : ''}${key}=${querysToUse[key]}`
  })
  return url
}

const Module = () => {
  return {
    namespaced: true,
    state: {
      dealer: false,
      dealerName: false,
      translation: false,
      rarLink: false,
      demonstration: false,
      comments: false,
      style: {
        banner: false,
        wizzard: false,
        brand: false,
        fontFace: false
      }
    },
    mutations: {
      SET_DEALER(state, payload) {
        state.dealer = payload.data
      },
      SET_COMMENTS(state, payload) {
        state.comments = payload.data
      },
      SET_DEALER_NAME(state, payload) {
        state.dealerName = payload.data.name
      },
      SET_TRANSLATION(state, payload) {
        state.translation = payload.data
      },
      SET_RARLINK(state, payload) {
        state.rarLink = payload.data
      },
      SET_DEMONSTRATION(state, payload) {
        state.demonstration = payload.data
      },
      SET_CSS_BANNER_VARIABLES(state, { data }) {
        state.style.banner = data
      },
      SET_CSS_WIZZARD_VARIABLES(state, { data }) {
        state.style.wizzard = data
      },
      SET_FONT_FACE(state, { data }) {
        state.style.fontFace = data
      },
      SET_BRAND(state, { data }) {
        state.style.brand = {
          ...data,
          src: process.env.VUE_APP_ROOT_API + data.src,
          alt: data.alt
        }
      },
      NOTHING() {}
    },
    actions: {
      getDealer({ commit }, { querys, wizardMode }) {
        Vue.axios
          .get(`${process.env.VUE_APP_ROOT_API}/dealer${parseQuerys(querys)}`)
          .then(response => {
            if (
              response.data &&
              response.data.dealer &&
              response.data.dealer.statusCode === 200
            ) {
              commit({
                type: 'SET_DEALER',
                data: response.data.dealer.data
              })

              commit({
                type: 'SET_COMMENTS',
                data: response.data.comments.data
              })

              commit({
                type: 'SET_TRANSLATION',
                data: response.data.translation
              })

              if (response.data && response.data.warLink.status === 200) {
                commit({
                  type: 'SET_RARLINK',
                  data: response.data.warLink.feedbackLink
                })
              }
            } else {
              commit({
                type: 'SET_DEALER',
                data: wizardMode ? demoDealerData().dealer : false
              })

              commit({
                type: 'SET_DEMONSTRATION',
                data: wizardMode ? true : false
              })

              commit({
                type: 'SET_TRANSLATION',
                data: wizardMode ? demoDealerData().translation : false
              })

              commit({
                type: 'SET_COMMENTS',
                data: wizardMode ? demoDealerData().comments : false
              })

              commit({
                type: 'SET_RARLINK',
                data: wizardMode ? demoDealerData().rarLink : false
              })
            }

            if (response.data.style && response.data.style.banner) {
              commit({
                type: 'SET_CSS_BANNER_VARIABLES',
                data: response.data.style.banner
              })
            }
            if (response.data.style && response.data.style.wizzard) {
              commit({
                type: 'SET_CSS_WIZZARD_VARIABLES',
                data: response.data.style.wizzard
              })
            }
            if (response.data.style && response.data.style.brand) {
              commit({
                type: 'SET_BRAND',
                data: response.data.style.brand
              })
            }
            if (response.data.style && response.data.style.fontFace) {
              commit({
                type: 'SET_FONT_FACE',
                data: response.data.style.fontFace
              })
            }
          })
          .catch(() => {
            commit({
              type: 'SET_DEALER',
              data: wizardMode ? demoDealerData().dealer : false
            })

            commit({
              type: 'SET_DEMONSTRATION',
              data: wizardMode ? true : false
            })

            commit({
              type: 'SET_TRANSLATION',
              data: wizardMode ? demoDealerData().translation : false
            })

            commit({
              type: 'SET_COMMENTS',
              data: wizardMode ? demoDealerData().comments : false
            })

            commit({
              type: 'SET_RARLINK',
              data: wizardMode ? demoDealerData().rarLink : false
            })
          })
      },
      setDealerName({ commit }, name) {
        commit({
          type: 'SET_DEALER_NAME',
          data: name
        })
      },
      postStatistic({ commit }, body) {
        Vue.axios.post(
          `${process.env.VUE_APP_ROOT_API}/statistic`,
          qs.stringify(body),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        )
        commit({ type: 'NOTHING' })
      }
    }
  }
}

export { Module }
export default new Vuex.Store()
