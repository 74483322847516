<template>
  <div class="bars">
    <Bar
      v-for="(percent, index) in rating"
      :key="index"
      :id="`${5 - index}`"
      :percentage="percent"
    />
  </div>
</template>

<script>
import Bar from './Bar.vue'

export default {
  name: 'Bars',
  props: {
    rating: Array
  },
  components: {
    Bar
  }
}
</script>
