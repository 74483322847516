const demoDealerData = () => {
  return {
    comments: [
      {
        comment_id: '3275545',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text: 'Professioneel en vriendelijk .',
        comment_date: '03/17/2022',
        comment_timestamp: '1647471600000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-16T23:00:00.000Z'
      },
      {
        comment_id: '3270517',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text: 'Professioneel en vriendelik',
        comment_date: '03/10/2022',
        comment_timestamp: '1646866800000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-09T23:00:00.000Z'
      },
      {
        comment_id: '3237822',
        category_key: 'SALES',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text: 'They have been helpful and i felt valued as a customer',
        comment_date: '03/07/2022',
        comment_timestamp: '1646607600000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-06T23:00:00.000Z'
      },
      {
        comment_id: '3236125',
        category_key: 'SALES',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text: 'no comment',
        comment_date: '03/02/2022',
        comment_timestamp: '1646175600000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-01T23:00:00.000Z'
      },
      {
        comment_id: '3241017',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text: 'Good treatment. Thank you.',
        comment_date: '03/02/2022',
        comment_timestamp: '1646175600000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-01T23:00:00.000Z'
      },
      {
        comment_id: '3224430',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text:
          'It was my first time have use *** service and I was highly satisfied with the service.',
        comment_date: '03/02/2022',
        comment_timestamp: '1646175600000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-01T23:00:00.000Z'
      },
      {
        comment_id: '3229543',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text:
          'The level of understanding and willingness to help displayed by the staff was amazing. The friendliness and effeciency was just what I needed that day. Thumbs up to the ***!',
        comment_date: '03/02/2022',
        comment_timestamp: '1646175600000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-01T23:00:00.000Z'
      },
      {
        comment_id: '3236098',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text: 'Because i got good services',
        comment_date: '03/02/2022',
        comment_timestamp: '1646175600000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-03-01T23:00:00.000Z'
      },
      {
        comment_id: '3235951',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text:
          'The service was great and had not any complaints about their service',
        comment_date: '03/01/2022',
        comment_timestamp: '1646089200000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-02-28T23:00:00.000Z'
      },
      {
        comment_id: '3220868',
        category_key: 'SERVICE',
        dealer_id: 'ZAFV05712',
        comment_rating: '5',
        comment_text: 'no comment',
        comment_date: '02/28/2022',
        comment_timestamp: '1646002800000',
        comment_ratings: ['100%', '100%', '100%', '100%', '100%'],
        comment_date_iso: '2022-02-27T23:00:00.000Z'
      }
    ],
    dealer: {
      avg_rating: 4.7,
      dealer_id: 'ZAFV05712',
      rating_categories: [
        {
          category_avg_rating: 4.9,
          category_key: 'SALES',
          category_ratings: [90.2, 7.3, 2.4, 0, 0],
          category_total_ratings: 41,
          category_avg_ratings: ['100%', '100%', '100%', '100%', '88%']
        },
        {
          category_avg_rating: 4.5,
          category_key: 'SERVICE',
          category_ratings: [74, 15.4, 4.4, 2.8, 3.4],
          category_total_ratings: 611,
          category_avg_ratings: ['100%', '100%', '100%', '100%', '54%']
        }
      ],
      total_ratings: 652,
      avg_ratings: ['100%', '100%', '100%', '100%', '71%']
    },
    translation: {
      dir: 'ltr',
      overall_rating: 'Overall Rating',
      reviews: 'Reviews',
      interviewee: 'Interviewee',
      all_reviews: 'All reviews',
      review_button: 'Write a Review',
      read_more: 'Read more',
      star: 'Star',
      stars: 'Stars',
      dealers_answer: "Dealer's answer",
      dealer_reviews: 'Dealer Reviews',
      dealer_text:
        'The reviews shown are from a sample of real customers who bought a car or had their car serviced at the dealership. Only reviews from customers who explicitly allowed their review to be published on the internet are shown. Inappropriate language or personal data may have been removed.',
      review_button_info:
        'We use your feedback for internal quality assurance. It will not be published and will not be included in the displayed ratings of new car buyers and service customers. Thank you',
      sales: 'Sales',
      service: 'Service'
    },
    warLink: { status: 400, message: 'no market configured' }
  }
}

export { demoDealerData }
