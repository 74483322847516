<template>
  <div class="star">
    <svg height="24" width="24" viewBox="0 0 24 24">
      <linearGradient
        :id="id"
        :x1="translation.dir == 'rtl' ? '100%' : '0%'"
        :x2="translation.dir == 'rtl' ? '0%' : '100%'"
        y1="0"
        y2="0"
      >
        <stop
          :offset="percentage"
          stop-color="var(--bps-star--filled, #faaa00)"
        ></stop>
        <stop :offset="percentage" stop-color="var(--bps-star, #e1e8ed)"></stop>
      </linearGradient>
      <path
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        :fill="`url(#${id})`"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Star',
  props: {
    id: String,
    percentage: String
  },
  computed: {
    translation() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].translation
        : null
    }
  }
}
</script>
