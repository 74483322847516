<template>
  <div id="popup" class="popup" :class="dir">
    <div class="popup__wrapper" :class="{ open: open }">
      <div
        id="js-popup__toggle--banner__popup"
        class="popup__toggle"
        ref="toggle"
        @click="popup"
      ></div>

      <div
        class="popup__content"
        :class="{ 'no-transition': noTransition }"
        :style="{
          width: popupContentStyle.width,
          height: popupContentStyle.height,
          top: popupContentStyle.top,
          left: popupContentStyle.left
        }"
      >
        <div class="popup__inner">
          <div>
            <slot><!-- popup slot --></slot>
          </div>
          <div class="close" @click="popup">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <title>Close</title>
              <path
                d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"
              ></path>
              <path
                d="M21 8l-5 5-5-5-3 3 5 5-5 5 3 3 5-5 5 5 3-3-5-5 5-5z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" appear>
      <div class="popup__overlay" v-if="showpopup" @click="popup"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  props: {
    dir: {
      type: String,
      default: 'ltr'
    }
  },
  data() {
    return {
      showpopup: false,
      open: false,
      noTransition: true,
      popupContentStyle: {
        width: 0,
        height: 0,
        top: 0,
        left: 0
      }
    }
  },
  mounted() {
    const vm = this
    vm.getButton()
  },
  methods: {
    getButton: function() {
      const vm = this
      const button = vm.$refs.toggle.getBoundingClientRect()

      vm.popupContentStyle.width = `${button.width}px`
      vm.popupContentStyle.height = `${button.height}px`
      vm.popupContentStyle.top = `${button.top}px`
      vm.popupContentStyle.left = `${button.left}px`
    },
    popup: function() {
      const vm = this
      vm.getButton()

      setTimeout(function() {
        if (vm.showpopup) {
          vm.showpopup = false
          vm.open = false
          vm.noTransition = true
          document.body.style.overflow = ''
          vm.$emit('update', false)
        } else {
          document.body.style.overflow = 'hidden'
          vm.noTransition = false
          vm.showpopup = true
          vm.open = true
          vm.$emit('update', true)

          setTimeout(function() {
            vm.noTransition = true
          }, 500)
        }
      }, 25)
    }
  }
}
</script>
