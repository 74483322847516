<template>
  <div class="stars">
    <Star
      v-for="(percent, index) in rating"
      :key="index"
      :id="id + index"
      :percentage="percent"
    />
  </div>
</template>

<script>
import Star from "./Star.vue";

export default {
  name: "Stars",
  props: {
    id: {
      type: String,
      default: "star"
    },
    rating: {
      type: Array,
      default: function() {
        return ["0%", "0%", "0%", "0%", "0%"];
      }
    }
  },
  components: {
    Star
  }
};
</script>
