<template>
  <div
    v-if="translation && dealer"
    id="bps"
    :style="banner"
    :class="[
      align ? `align--${align}` : '',
      translation.dir ? translation.dir : ''
    ]"
  >
    <div
      class="bps"
      :class="[size ? `${size}` : '']"
      itemscope="itemscope"
      itemtype="https://schema.org/AutoDealer"
    >
      <div v-if="wizardMode && demonstration" class="demonstration-data">
        Demonstration Data:
      </div>

      <div class="sr-only">
        <meta itemprop="name" :content="dealerName" />
      </div>

      <CaradvisorLogo
        v-if="brand && brand.showCaradvisor"
        class="car-advisor"
        :class="[size ? `${size}` : '']"
        alt="Caradvisor Logo"
      />

      <img
        v-if="brand"
        :src="brand.src"
        :alt="brand.alt"
        itemprop="image"
        class="brand"
        :class="[size ? `${size}` : '']"
      />

      <Tooltip
        v-if="brand && brand.showTooltip"
        class="bps-tooltip"
        :text="translation.dealer_text"
      />

      <Popup v-if="popup" :dir="translation.dir">
        <Detail :hide-comments="hideComments" />
      </Popup>

      <Dealer :schema="true" />
    </div>
  </div>
</template>

<script>
import CaradvisorLogo from './assets/car-advisor.svg'
import Popup from './components/Popup.vue'
import Dealer from './components/rating/Dealer.vue'
import Detail from './components/Detail.vue'
import store, { Module } from './store'
import Tooltip from './components/Tooltip.vue'

export default {
  store,
  name: 'Banner',
  components: {
    Popup,
    Dealer,
    Detail,
    CaradvisorLogo,
    Tooltip
  },
  computed: {
    banner() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].style.banner
        : null
    },
    fontFace() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].style.fontFace
        : null
    },
    translation() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].translation
        : null
    },
    error() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].error
        : null
    },
    dealer() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].dealer
        : null
    },
    demonstration() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].demonstration
        : null
    },
    rarLink() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].rarLink
        : null
    },
    brand() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].style.brand
        : null
    }
  },
  props: {
    hideComments: {
      type: Boolean,
      default: false
    },
    wizardMode: {
      type: Boolean,
      default: false
    },
    popup: {
      type: Boolean,
      default: false
    },
    isIframe: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'center'
    },
    size: {
      type: String,
      default: 'medium'
    },
    dealerName: {
      type: String,
      default: ''
    },
    dealerId: {
      type: String,
      default: ''
    },
    chainSalesId: {
      type: String,
      default: ''
    },
    chainAfterSalesId: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      default: 'enXX'
    },
    category: {
      type: Number,
      default: 0 // 0 both, 1 sales, 2 service
    }
  },
  watch: {
    language: function() {
      this.$root.uuid = `${this.dealerId}_${this.language}`.toLowerCase()
      if (!this.$store.hasModule(this.$root.uuid))
        this.$store.registerModule(this.$root.uuid, Module())

      this.$store.dispatch(
        `${this.$root.uuid}/getDealer`,
        {
          querys: {
            language: this.language,
            popup: this.popup,
            dealerId: this.dealerId,
            chainSalesId: this.chainSalesId,
            category: this.category,
            chainAfterSalesId: this.chainAfterSalesId
          },
          wizardMode: this.wizardMode
        },
        { root: true }
      )
    },
    dealerName: function() {
      this.$store.dispatch(
        `${this.$root.uuid}/setDealerName`,
        {
          name: this.dealerName
        },
        { root: true }
      )
    },
    popup: function() {
      this.$store.dispatch(
        `${this.$root.uuid}/getDealer`,
        {
          querys: {
            language: this.language,
            popup: this.popup,
            dealerId: this.dealerId,
            chainSalesId: this.chainSalesId,
            category: this.category,
            chainAfterSalesId: this.chainAfterSalesId
          },
          wizardMode: this.wizardMode
        },
        { root: true }
      )
    },
    dealerId: function() {
      this.$root.uuid = `${this.dealerId}_${this.language}`.toLowerCase()
      if (!this.$store.hasModule(this.$root.uuid))
        this.$store.registerModule(this.$root.uuid, Module())

      this.$store.dispatch(
        `${this.$root.uuid}/getDealer`,
        {
          querys: {
            language: this.language,
            popup: this.popup,
            dealerId: this.dealerId,
            chainSalesId: this.chainSalesId,
            category: this.category,
            chainAfterSalesId: this.chainAfterSalesId
          },
          wizardMode: this.wizardMode
        },
        { root: true }
      )
    },
    chainSalesId: function() {
      this.$store.dispatch(
        `${this.$root.uuid}/getDealer`,
        {
          querys: {
            language: this.language,
            popup: this.popup,
            dealerId: this.dealerId,
            chainSalesId: this.chainSalesId,
            category: this.category,
            chainAfterSalesId: this.chainAfterSalesId
          },
          wizardMode: this.wizardMode
        },
        { root: true }
      )
    },
    chainAfterSalesId: function() {
      this.$store.dispatch(
        `${this.$root.uuid}/getDealer`,
        {
          querys: {
            language: this.language,
            popup: this.popup,
            dealerId: this.dealerId,
            chainSalesId: this.chainSalesId,
            category: this.category,
            chainAfterSalesId: this.chainAfterSalesId
          },
          wizardMode: this.wizardMode
        },
        { root: true }
      )
    },
    category: function() {
      this.$store.dispatch(
        `${this.$root.uuid}/getDealer`,
        {
          querys: {
            language: this.language,
            popup: this.popup,
            dealerId: this.dealerId,
            chainSalesId: this.chainSalesId,
            category: this.category,
            chainAfterSalesId: this.chainAfterSalesId
          },
          wizardMode: this.wizardMode
        },
        { root: true }
      )
    },
    fontFace: function(fontFace) {
      if (fontFace && Array.isArray(fontFace)) {
        this.$loadFonts(fontFace)
      }
    }
  },
  mounted() {
    this.$root.uuid = `${this.dealerId}_${this.language}`.toLowerCase()
    if (!this.$store.hasModule(this.$root.uuid))
      this.$store.registerModule(this.$root.uuid, Module())

    this.$store.dispatch(
      `${this.$root.uuid}/setDealerName`,
      {
        name: this.dealerName
      },
      { root: true }
    )

    this.$store.dispatch(
      `${this.$root.uuid}/getDealer`,
      {
        querys: {
          language: this.language,
          popup: this.popup,
          dealerId: this.dealerId,
          chainSalesId: this.chainSalesId,
          category: this.category,
          chainAfterSalesId: this.chainAfterSalesId
        },
        wizardMode: this.wizardMode
      },
      { root: true }
    )

    if (!this.wizardMode && this.dealerId !== '' && this.language !== '') {
      this.$store.dispatch(`${this.$root.uuid}/postStatistic`, {
        dealerCode: this.dealerId,
        language: this.language,
        isIframe: this.isIframe,
        isAdvanced: this.popup,
        bannerSize: this.size[0],
        category: ['both', 'sales', 'service'][this.category]
      })
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/banner.scss';
</style>
