<template>
  <div class="tooltip">
    <div class="icon" :title="tooltip">
      <InfoIcon />
    </div>
  </div>
</template>

<script>
import InfoIcon from '../assets/icons/info-circle-solid.svg'

export default {
  name: 'Tooltip',
  components: {
    InfoIcon
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      open: false
    }
  },
  computed: {
    tooltip() {
      return this.text
        .split(/&#010;|&#10;/)
        .join(String.fromCharCode(10))
        .split(/&#013;|&#13;/)
        .join(String.fromCharCode(13))
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  .icon {
    position: absolute;
    cursor: pointer;
    pointer-events: all;
    transform: translate(50%, -50%);
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    color: #faaa00;
    color: var(--bps-button-tooltip, #faaa00);
    font-size: 12px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
