export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$loadFonts = function(fonts) {
      fonts.forEach(data => {
        const source = data.src
          .map(
            src =>
              `url("${process.env.VUE_APP_ROOT_API + src.url}") format("${
                src.format
              }")`
          )
          .join(", ");

        const font = new FontFace(data.family, source, {
          style: data.style,
          weight: data.weight
        });

        font
          .load()
          .then(function(loadedFont) {
            console.log("success");
            document.fonts.add(loadedFont);
          })
          .catch(function(error) {
            console.log(error);
          });
      });
    };
  }
};
