<template>
  <div class="review-comment">
    <div class="review-comment__inner">
      <div class="sr-only">
        <div
          itemscope="itemscope"
          itemprop="review"
          itemtype="https://schema.org/Review"
        >
          <meta itemprop="itemreviewed" :content="dealerName" />
          <meta itemprop="datePublished" :content="comment.comment_date_iso" />
          <meta itemprop="author" :content="translation.interviewee" />
          <div
            itemscope="itemscope"
            itemprop="reviewRating"
            itemtype="https://schema.org/Rating"
          >
            <meta itemprop="worstRating" content="1" />
            <meta itemprop="ratingValue" :content="comment.comment_rating" />
            <meta itemprop="bestRating" content="5" />
            <meta itemprop="description" content="" />
            <meta itemprop="reviewBody" :content="comment.comment_text" />
          </div>
        </div>
      </div>

      <div class="review-comment__content width--50 text--left">
        <Stars
          class="tiny"
          :id="'comment' + comment.comment_id"
          :rating="comment.comment_ratings"
        />
      </div>

      <div class="review-comment__content width--50 text--right">
        <p class="color--comment date">{{ comment.comment_date }}</p>
      </div>
      <div class="review-comment__content width--100">
        <p class="p--small text--extra-bold">
          {{ translation[comment.category_key.toLowerCase()] }}
        </p>
        <p
          v-if="
            comment.comment_text && comment.comment_text.length >= maxLength
          "
        >
          <template v-if="!showTotalCustomerComment">
            <span v-html="truncateText(comment.comment_text)"></span>
            <div @click="toggleTotalCustomerComment()" class="read-more">
              {{ translation.read_more }}
            </div>
          </template>
          <template v-else>
            <span v-html="comment.comment_text"></span>
          </template>
        </p>
        <p v-else>
          <span v-html="comment.comment_text"></span>
        </p>
      </div>
      <div
        v-if="
          comment.dealer_response &&
            comment.dealer_response.dealer_response_text
        "
        class="width-100 background--comment review-comment__dealer"
      >
        <span class="date-dealer">{{
          comment.dealer_response.dealer_response_date
        }}</span>
        <p class="p--small text--extra-bold">
          {{ translation.dealers_answer }}
        </p>

        <p
          v-if="
            comment.dealer_response.dealer_response_text &&
              comment.dealer_response.dealer_response_text.length >= maxLength
          "
        >
          <template v-if="!showTotalDealerResponse">
            <span
              v-html="
                truncateText(comment.dealer_response.dealer_response_text)
              "
            ></span>
            <div @click="toggleTotalDealerResponse()" class="read-more">
              {{ translation.read_more }}
            </div>
          </template>
          <template v-else>
            <span v-html="comment.dealer_response.dealer_response_text"></span>
          </template>
        </p>
        <p v-else>
          <span v-html="comment.dealer_response.dealer_response_text"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from '../rating/Stars.vue'

export default {
  name: 'Comment',
  components: {
    Stars
  },
  props: {
    comment: Object,
    id: Number
  },
  data() {
    return {
      maxLength: 80,
      clamp: '...',
      showTotalCustomerComment: true,
      showTotalDealerResponse: true
    }
  },
  computed: {
    translation() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].translation
        : null
    },
    dealerName() {
      return this.$store.state[this.$root.uuid]
        ? this.$store.state[this.$root.uuid].dealerName
        : null
    }
  },
  methods: {
    toggleTotalCustomerComment: function() {
      this.showTotalCustomerComment = !this.showTotalCustomerComment
      this.$emit('resize')
    },
    toggleTotalDealerResponse: function() {
      this.showTotalDealerResponse = !this.showTotalDealerResponse
      this.$emit('resize')
    },
    truncateText: function(value) {
      if (!value) return ''
      return value.substring(0, this.maxLength) + this.clamp
    },
    init: function() {
      if (
        this.comment.comment_text &&
        this.comment.comment_text.length >= this.maxLength
      ) {
        this.showTotalCustomerComment = false
      }

      if (
        this.comment.dealer_response &&
        this.comment.dealer_response.dealer_response_text &&
        this.comment.dealer_response.dealer_response_text.length >=
          this.maxLength
      ) {
        this.showTotalDealerResponse = false
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>
