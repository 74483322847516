<template>
  <div class="number">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 500 100"
      preserveAspectRatio="xMinYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <text
        x="50%"
        y="60%"
        dominant-baseline="middle"
        text-anchor="middle"
        font-size="120"
        fill="black"
      >
        {{ number }}
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Number",
  props: {
    number: Number
  }
};
</script>
